import { Swiper, SwiperSlide } from "swiper/react";
import {useRef} from "react";

import { Navigation, Pagination, Autoplay } from "swiper/modules";
import circle from "../../../assets/images/icons/circle.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

const CustomSwiper = () => {
  const swiperRefLocal = useRef<any>();
  const navigationPrevRef = useRef<any>(); // Add this line

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Swiper
      className="text-center w-75"
      effect="fade"
      ref={swiperRefLocal}
        // navigation={{
        //   prevEl: navigationPrevRef.current,
        //   nextEl: navigationPrevRef.current, // Add this line
        // }}
      fadeEffect={{
        crossFade: true,
      }}
      loop={true}
      // navigation
      pagination
      slidesPerView={3}
      autoplay={{
        delay: 2000,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400: {
          slidesPerView: 2,
        },
        639: {
          slidesPerView: 3,
        },
      }}
      modules={[Autoplay, Pagination, Navigation]}
    >
      <SwiperSlide className="container">
        <div className="text-center d-flex flex-column align-items-center gap-3 cardSwiper mb-5 container p-3">
          <div>
            <h6 className="mb-0">GESTION SYNDIC</h6>
            <img src={circle} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur
            obcaecati mollitia voluptatum fugiat laudantium inventore, maxime
            voluptas beatae? Tenetur labore illum quam expedita ducimus
            assumenda debitis et tempore earum itaque.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide className="container">
        <div className="text-center d-flex flex-column align-items-center gap-3 cardSwiper mb-5 container p-3">
          <div>
            <h6 className="mb-0">TRAVAUX RÉNOVATION</h6>
            <img src={circle} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur
            obcaecati mollitia voluptatum fugiat laudantium inventore, maxime
            voluptas beatae? Tenetur labore illum quam expedita ducimus
            assumenda debitis et tempore earum itaque.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide className="container">
        <div className="text-center d-flex flex-column align-items-center gap-3 cardSwiper mb-5 container p-3">
          <div>
            <h6 className="mb-0">NETTOAYAGE</h6>
            <img src={circle} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur
            obcaecati mollitia voluptatum fugiat laudantium inventore, maxime
            voluptas beatae? Tenetur labore illum quam expedita ducimus
            assumenda debitis et tempore earum itaque.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide className="container">
        <div className="text-center d-flex flex-column align-items-center gap-3 cardSwiper mb-5 container p-3">
          <div>
            <h6 className="mb-0">TITLE</h6>
            <img src={circle} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur
            obcaecati mollitia voluptatum fugiat laudantium inventore, maxime
            voluptas beatae? Tenetur labore illum quam expedita ducimus
            assumenda debitis et tempore earum itaque.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
    </div>
  
  );
};

export default CustomSwiper;
