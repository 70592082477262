import React from 'react'
import "./About.css"
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import FirstSection from '../../components/FirstSection'
import { useTranslation } from 'react-i18next';
import SecondSection from './partial/SecondSection';
import ThirdSection from './partial/ThirdSection';
import FourthSection from './partial/FourthSection';
import FifthSection from './partial/FifthSection';
import SixthSection from './partial/SixthSection';
import SeventhSection from './partial/SeventhSection'

function About() {
  const { t, i18n } = useTranslation();

  return (
  <div >
      <Navbar />
      <FirstSection title={t("about.headers.title")}/>
      <SecondSection/>
      <ThirdSection/>
      <FourthSection/>
      <FifthSection/>
      <SixthSection/>
      <SeventhSection/>
      <Footer />

    </div>
  )
}

export default About
