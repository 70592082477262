import React from 'react'
import {useTranslation} from "react-i18next";
type Props={
  title?:string
}
const  FirstSection :React.FC<Props>=({title})=> {

  return (
    <div className='firs_section_home'>
       
      <h2>{title}</h2>
    </div>
  )
}

export default FirstSection
