import React from 'react'
import comp from "../../../assets/images/Compagnie.png"
import hand from "../../../assets/images/HandAbout.png"

function SeventhSection() {
  return (
    <div className='SeventhSectionAbout espaceSection d-flex flex-column align-items-center'>
        <h2>NOTRE HISTOIRE</h2>
        <div className="dangerLigne"></div>


        <div className=' mt-5   d-flex justify-content-center flex-column align-items-center'>
            <div className='d-flex  aboutSectionHistoire'>
                <div className='w-75'>
                    <h3>l'équipe</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ipsa doloremque, hic velit placeat architecto alias quos exercitationem quam ex repellendus voluptatibus rerum officiis error illum nesciunt magni? Quod, fugit.</p>
                </div>
                <div>
                    <img src={hand} alt="" />
                 </div>
        </div>


            <div className='d-flex  aboutSectionHistoire'>
            <div>
                <img src={comp} alt="" />
            </div>
            <div className='w-75'>
                <h3>l'équipe</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt ipsa doloremque, hic velit placeat architecto alias quos exercitationem quam ex repellendus voluptatibus rerum officiis error illum nesciunt magni? Quod, fugit.</p>
            </div>
            
            </div>
        </div>
    </div>
  )
}

export default SeventhSection