import React from 'react'


function FourthSection() {
  return (
    <div className='FourthSectionAbout  d-flex flex-column align-items-center justify-content-center'>
      <h2 className='text-white'>ACTIVER LA RÉUSSITE DE VOS PROJETS</h2>
    </div>
  )
}

export default FourthSection