import React from 'react'
import eleps from "../../../assets/images/icons/Ellipse.png";
import eleps2 from "../../../assets/images/icons/Group 67.png";
import circles from "../../../assets/images/icons/circle.png";


function SecondSection() {
  return (
    <div className='second_section_about d-flex  flex-column align-items-center espaceSection'>
        <div className='danger_ligne'></div>
        <h2>Madame, Monsieur,</h2>
        <p className='text-center w-50 firstP'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis porro facilis adipisci rerum eveniet ex blanditiis qui ab. Voluptatibus eligendi doloremque quos facere asperiores similique numquam tenetur, eos at est?</p>
        <img src={eleps} alt=""  className='img eleps'/>
        <img src={eleps2} alt=""  className='img eleps2'/>
        <img src={circles} alt=""  className='eleps3'/>
    </div>
  )
}

export default SecondSection