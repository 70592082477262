import React from "react";
import CustomTab from "./CustomTab";
import CardComponent from "./CardCompinant";
import { NavLink } from "react-router-dom";
import points from "../../../assets/images/icons/points.png";

import eleps from "../../../assets/images/icons/elepse2.png";
import eleps8 from "../../../assets/images/icons/Ellipse 8.png";
import { useTranslation } from "react-i18next";

function FourthSection() {
  const { t } = useTranslation();
  return (
    <div className="section_four_home  mb-5">
      <img src={points} alt="" className="points" />
      <img src={eleps} alt="" className="eleps" />
      <img src={eleps8} alt="" className="eleps_8" />
      <div className="first_div mb-3">
        <h2>{t("home.headers.4")}</h2>
        <div></div>
      </div>

      <CustomTab />
      <CardComponent />

      <NavLink to="/" className="CustomLink">
        {t("buttons.readMore")}
      </NavLink>
    </div>
  );
}

export default FourthSection;
