import React from "react";
import Inputs from "../layouts/Inputs";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
function ContactForm() {
  const { t } = useTranslation();
  return (
    <div className="contact-form container position-relative  w-50  contact_div">
      <h2 className=" mb-5 align-self-start ">{t("footer.headers.1")}</h2>
      <div className="d-flex flex-column gap-5 position-relative w-100">
        <Inputs type="name" name="name" placeholder={`${t("labels.firstName")} & ${t("labels.lastName")}` } />
        <Inputs type="email" name="email" placeholder={`${t("labels.email")}` } />
        <Inputs type="phone" name="phone" placeholder={`${t("labels.phone")}` }/>
        <Inputs type="text" name="message" placeholder={`${t("labels.message")}` } />
        <button type="submit" className="btn sub">
          Envoyer
        </button>
      </div>
    </div>
  );
}

export default ContactForm;
