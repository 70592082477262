import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Save from "../../../assets/images/icons/Save.png";

const cardData = [
  {
    id: 1,
    image:
      "https://th-thumbnailer.cdn-si-edu.com/GlAULlYo7cwjnL0Jil_HPf3EwsE=/1000x750/filters:no_upscale():focal(2834x1791:2835x1792)/https://tf-cmsv2-smithsonianmag-media.s3.amazonaws.com/filer/70/3d/703d79bb-5568-4e9c-a973-385ceff8bb17/ky36a2.jpg",
    description: "Description for card 1",
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1624204386084-dd8c05e32226?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
    description: "Description for card 2",
  },
  {
    id: 3,
    image:
      "https://media.istockphoto.com/id/863561484/photo/living-room-with-table.jpg?s=612x612&w=0&k=20&c=eO1hkbZnpjm7fgkO0F_Ea0oCh2WMoHPvJO4uluYH2oo=",
    description: "Description for card 3",
  },
  {
    id: 4,
    image:
      "https://media.istockphoto.com/id/863561484/photo/living-room-with-table.jpg?s=612x612&w=0&k=20&c=eO1hkbZnpjm7fgkO0F_Ea0oCh2WMoHPvJO4uluYH2oo=",
    description: "Description for card 4",
  },
  {
    id: 5,
    image:
      "https://images.pexels.com/photos/259950/pexels-photo-259950.jpeg?cs=srgb&dl=pexels-pixabay-259950.jpg&fm=jpg",
    description: "Description for card 4",
  },
  {
    id: 6,
    image:
      "https://img.freepik.com/premium-photo/apartment-residential-building-exterior-housing-structure-blue-modern-house-europe-rental-home-city-district-summer-architecture-business-property-investment-vilnius-lithuania_250132-3206.jpg?size=626&ext=jpg&ga=GA1.1.1826414947.1699315200&semt=ais",
    description: "Description for card 4",
  },
  {
    id: 7,
    image:
      "https://media.istockphoto.com/id/863561484/photo/living-room-with-table.jpg?s=612x612&w=0&k=20&c=eO1hkbZnpjm7fgkO0F_Ea0oCh2WMoHPvJO4uluYH2oo=",
    description: "Description for card 4",
  },
  {
    id: 8,
    image:
      "https://images.unsplash.com/photo-1624204386084-dd8c05e32226?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YXBhcnRtZW50JTIwYnVpbGRpbmd8ZW58MHx8MHx8fDA%3D",
    description: "Description for card 4",
  },
];

const CardComponent = () => {
  return (
    <Container className="w-100">
      <Row>
        {cardData.map((card) => (
          <Col key={card.id} lg={3} md={6} sm={12}>
            <Card style={{ margin: "10px", position: "relative" }}>
              <Card.Img
                variant="top"
                src={card.image}
                style={{ width: "100%", height: "12rem" }}
              />
              <Card.Body>
                <Card.Text>{card.description}</Card.Text>
                <div className="d-flex justify-content-between align-items-end ">
                  <div className="align-items-bottom my-0">
                    <p className="my-0 text1">100 000 dh</p>
                    <p className="my-0">City </p>
                  </div>
                  <div className="align-items-bottom">
                    <img src={Save} alt="" />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CardComponent;
