import React, { useEffect } from "react";
import ContactForm from "./ContactForm";
import fb from "../assets/images/icons/facebook.png";
import lin from "../assets/images/icons/linkedin.png";
import ins from "../assets/images/icons/instagram.png";
import yb from "../assets/images/icons/youtube.png";
import eleps from "../assets/images/icons/Ellipse.png";
import CopyRight from "./CopyRight";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
function Footer() {
  const {t}=useTranslation()
  useEffect (() => {
    AOS.init();
  }, []);
  return (
    <footer className="pe-0 ">
      <div className="footer  mt-5  pe-0 d-flex   ">
        <div className="contact-div col-lg-6 col-sm-12 col-md-6 d-flex align-items-center">
          <div className="w-100  ">
            <div className="w-100 ">
              <ContactForm />
            </div>
          </div>
          {/* <div>
            <CopyRight />
          </div> */}
          <img src={eleps} className="center_eleps" alt="" />
          <img src={eleps} className="top_left_eleps" alt="" />
          <img src={eleps} className="bottom_left_eleps" alt="" />
        </div>

        <div className="backgroun_div col-lg-6 col-sm-12 col-md-6  "  data-aos="fade-right" data-aos-delay="500">
          <div className="bg-white  child_div d-flex flex-column justify-content-space-between align-items-start gap-2"  data-aos="fade-left" data-aos-delay="1000">
            <div>
              <h2 className="mb-0">{t("footer.headers.2")}</h2>
            </div>
            <div>
              <p className="mb-0 mt-0">102 Rue Smara, Saïdia 60300</p>
            </div>
            <div>
              <a href="tel:+06  614 - 22 646">06 614 - 22 646</a>
            </div>
            <div>
              <a href="mailto:Prestacia@gmail.com">Prestacia@gmail.com</a>
            </div>
            <div className="SocialMedia">
              <i>
                <a href="#" target="_blank">
                  <img src={fb} alt="" />
                </a>
              </i>
              <i>
                <a href="#" target="_blank">
                  {" "}
                  <img src={lin} alt="" />
                </a>
              </i>
              <i>
                {" "}
                <a href="#" target="_blank">
                  {" "}
                  <img src={ins} alt="" />
                </a>
              </i>
              <i>
                {" "}
                <a href="#" target="_blank">
                  {" "}
                  <img src={yb} alt="" />
                </a>
              </i>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <CopyRight />
      </div>
    </footer>
  );
}

export default Footer;
