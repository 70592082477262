import React from "react";
import InfiniteSwiper from "./partial/InfiniteSwiper";
import points from "../../assets/images/icons/points.png";
import { useTranslation } from "react-i18next";
function FifthSection() {
  const { t } = useTranslation();
  return (
    <div className="section_five_home mb-5">
      <div className="first_div">
        <h2>{t("home.headers.5")} </h2>
        <div></div>
      </div>
      <img src={points} alt="" className="section_five_image" />

      <div className="w-75 mt-3">
        <InfiniteSwiper />
      </div>
    </div>
  );
}

export default FifthSection;
