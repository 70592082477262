import React from "react";
import { NavLink } from "react-router-dom";
import frnace from "../assets/images/icons/France.png";
import english from "../assets/images/icons/english.png";
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    
    i18n.changeLanguage(lng);
  };
  
  return (
    <div>
      <nav className="navbar navHome navbar-expand-lg ">
        <div className="container-fluid">
          {/* Logo on the left */}
          <NavLink to={"/"} style={{ all: "unset" }}>
            <a href="#" className="navbar-brand">
              <img src="/logo.png" alt="logo" />
            </a>
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Menu in the center */}
          <div
            className="collapse navbar-collapse justify-content-center"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav gap-5">
              {/* <li className="nav-item"></li> */}
              <li className="nav-item">
                <NavLink className="nav-link " aria-current="page" to={"/"}>
                {t('menu.home')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={"/about"}>
                {t('menu.about')}
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                {t('menu.services')}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                {t('menu.contact')}
                </a>
              </li>
              <li className="nav-item dropdown">
  <a
    className="nav-link dropdown-toggle d-none"
    href="#"
    id="dropDowantranslate"
    role="button"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
   <i className="me-2">
                  <img src={i18n.language === 'fr' ? frnace : english}     width={20} />
                </i>
                {i18n.language === 'fr' ? t('menu.french') : t('menu.english')}
  </a>
  <ul
    className="dropdown-menu "
    aria-labelledby="dropDowantranslate"
  >
    <li>
      <a className="dropdown-item" href="#"  onClick={() => changeLanguage('fr')}>
      <i className="me-2">
      <img src={frnace} alt="" width={20} />
                </i>
                {t('menu.french')}      </a>
    </li>
    <li>
      <a className="dropdown-item" href="#"  onClick={() => changeLanguage('en')}>
      <i className="me-2">
                  <img src={english} alt="" width={20} />
                </i>
                {t('menu.english')}
      </a>
    </li>
   
  </ul>
</li>

            </ul>
          </div>

          {/* Language selector as a button with dropdown */}
          <div className="dropdown position-relative button_transaltes">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="me-2">
              <img src={i18n.language === 'fr' ? frnace : english}     width={20} />
              </i>
              {i18n.language === 'fr' ? t('menu.french') : t('menu.english')}
            </button>
            <div className="dropdown-menu " aria-labelledby="languageDropdown">
            <button className="dropdown-item" onClick={() => changeLanguage('fr')}>
                <i className="me-2">
                  <img src={frnace} alt="" width={20} />
                </i>
                {t('menu.french')}
              </button>
              <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                <i className="me-2">
                  <img src={english} alt="" width={20} />
                </i>
                {t('menu.english')}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
