import React from 'react'
import Rythm from "../../../assets/images/icons/RythmeBlue (2).png";
import Harmonie from "../../../assets/images/icons/Harmonie.png";
import Durabilite from "../../../assets/images/icons/Durabilite.png";
import Confiance from "../../../assets/images/icons/Confiance.png";
import { useTranslation } from 'react-i18next';
function FifthSection() {
  const { t } = useTranslation();

  return (
    <div className='FifthSectionAbout espaceSection'>
        <i>
            <img src={Rythm} alt="" />
           <p>{t("home.qualities.1")}</p>
        </i>
        <i>
            <img src={Harmonie} alt="" />
            <p>{t("home.qualities.2")}</p>

        </i>
        <i>
            <img src={Durabilite} alt="" />
            <p>{t("home.qualities.3")}</p>

        </i>
        <i>
            <img src={Confiance} alt="" />
            <p>{t("home.qualities.4")}</p>

        </i>
    </div>
  )
}

export default FifthSection