// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Autoplay } from "swiper/modules";
import icon1 from "../../../assets/images/icons/holcimlogo.png";
import icon2 from "../../../assets/images/icons/etvLogo.png";
import icon3 from "../../../assets/images/icons/westinLogo.png";
import icon4 from "../../../assets/images/icons/summitlogo.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

// const InfiniteSwiper = () => {
//   return (
//     <Swiper
//       className="text-center"
//       spaceBetween={50}
//       effect="fade"
//       fadeEffect={{
//         crossFade: true,
//       }}
//       loop={true}
//       slidesPerView={1}
//       autoplay={{
//         delay: 2000,
//       }}
//       modules={[Autoplay, Pagination, Navigation]}
//     >
//       <SwiperSlide>
//         <img src={icon1} alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src={icon2} alt="" />
//       </SwiperSlide>
//       <SwiperSlide>
//         <img src={icon3} alt="" />
//       </SwiperSlide>
//     </Swiper>
//   );
// };
// export default InfiniteSwiper;
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import circle from "../../../assets/images/icons/circle.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";

const CustomSwiper = () => {
  return (
    <Swiper
      className="text-center w-75"
      effect="fade"
      fadeEffect={{
        crossFade: true,
      }}
      loop={true}
      // navigation
      pagination
      slidesPerView={4}
      autoplay={{
        delay: 2000,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        400: {
          slidesPerView: 2,
        },
        639: {
          slidesPerView: 3,
        },
      }}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {/* <SwiperSlide className="container">
        <div className="text-center d-flex flex-column align-items-center gap-3 cardSwiper mb-5 container p-3">
          <div>
            <h6 className="mb-0">GESTION SYNDIC</h6>
            <img src={circle} alt="" />
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur
            obcaecati mollitia voluptatum fugiat laudantium inventore, maxime
            voluptas beatae? Tenetur labore illum quam expedita ducimus
            assumenda debitis et tempore earum itaque.
          </p>
        </div>
      </SwiperSlide> */}
      <SwiperSlide className="">
        <div className="w-100">
          <img src={icon1} alt="" />{" "}
        </div>
      </SwiperSlide>
      <SwiperSlide className="">
        <div className="w-100">
          <img src={icon2} alt="" />{" "}
        </div>
      </SwiperSlide>
      <SwiperSlide className="">
        <div className="w-100">
          <img src={icon3} alt="" />{" "}
        </div>
      </SwiperSlide>
      <SwiperSlide className="">
        <div className="w-100">
          <img src={icon4} alt="" />{" "}
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default CustomSwiper;
