import React from "react";
import Navbar from "../../components/Navbar";
// import FirstSection from "./partial/FirstSection";
import "./Home.css";
import SecondSection from "./partial/SecondSection";
import ThirdSection from "./partial/ThirdSection";
import FourthSection from "./partial/FourthSection";
import FifthSection from "./FifthSection";
import ContactForm from "../../components/ContactForm";
import ConatctSection from "./partial/ConatctSection";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import FirstSection from "../../components/FirstSection";
import { useTranslation } from "react-i18next";


function Home() {
  const { t, i18n } = useTranslation ();

  return (
    <div>
       <Helmet >
                <meta charSet="utf-8" />
                <title>Prestacia - Accueil </title>
             
            </Helmet>
      <Navbar />
      <FirstSection title={t("home.headers.title")} />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <Footer />
      {/* <ConatctSection /> */}
    </div>
  );
}

export default Home;
