import React, { useEffect } from 'react'
import icon1 from "../../../assets/images/icons/Client.png";
import icon2 from "../../../assets/images/icons/Services.png";
import icon3 from "../../../assets/images/icons/Project.png";
import { useTranslation } from 'react-i18next';
import CountUp, { useCountUp } from "react-countup";

import AOS from "aos";
import "aos/dist/aos.css";


function SixthSection() {
    const {t}=useTranslation ()
    useEffect (() => {
        AOS.init();
      }, []);
    
      useCountUp({
        ref: "counter",
        enableScrollSpy: true,
        scrollSpyDelay: 2000,
        end: 700,
      });
  return (
    <div className='SixthSectionAbout espaceSection'>
        <div className="listCard gap-4">
        <div className="myCard" data-aos="fade-right" data-aos-delay="500">
          <img src={icon1} alt="" />
          <span>{t("home.values.SATISFIED_CLIENTS")}</span>
          <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda voluptatem consequuntur, itaque dolorem dignissimos velit dolorum ullam et odit, .
          </p>
        </div>
        <div className="myCard" data-aos="fade-down" data-aos-delay="1000">
          <img src={icon2} alt="" />
          <span>{t("home.values.COMPLETE_PROJECT")}</span>
          <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda voluptatem consequuntur, itaque dolorem dignissimos velit dolorum ullam et odit,.
          </p>
        </div>
        <div className="myCard" data-aos="fade-left" data-aos-delay="1500">
          <img src={icon3} alt="" />
          <span>{t("home.values.SERVICE_QUALITY")}</span>
          <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Assumenda voluptatem consequuntur, itaque dolorem dignissimos velit dolorum ullam et odit, vero unde asperiores laudantium. Soluta, .
          </p>
        </div>
      </div>
    </div>
  )
}

export default SixthSection