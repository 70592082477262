import React from "react";

function CustomTab() {
  const [isActive, setIsActive] = React.useState("Gestion syndic");

  const handleTabClick = (tabName: string) => {
    setIsActive(tabName);
  };

  return (
    <div className="my-3 ">
      <ul className="nav nav-tabs  " style={{ gap: "5rem" }} id="myTab">
        <li className="nav-item">
          <button
            className={`nav-link ${isActive === "Gestion syndic" && "active"}`}
            aria-current="page"
            onClick={() => handleTabClick("Gestion syndic")}
          >
            Gestion syndic
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              isActive === "Travaux rénovation" && "active"
            }`}
            onClick={() => handleTabClick("Travaux rénovation")}
          >
            Travaux rénovation
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${isActive === "Nettoayage" && "active"}`}
            onClick={() => handleTabClick("Nettoayage")}
          >
            Nettoayage
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${isActive === "Ameublement" && "active"}`}
            onClick={() => handleTabClick("Ameublement")}
          >
            Ameublement
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              isActive === "Traveaux d'entretien et maintenance" && "active"
            }`}
            onClick={() =>
              handleTabClick("Traveaux d'entretien et maintenance")
            }
          >
            Traveaux d'entretien et maintenance
          </button>
        </li>
      </ul>
    </div>
  );
}

export default CustomTab;
