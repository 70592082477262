import React from 'react';
import icon1 from "../../../assets/images/icons/points.png";
{/* <p className='text-center mb-5'>
            Notre domaine d'expertise englobe la gestion complète des résidences et groupements d'habitations, ainsi que la responsabilité du nettoyage, de l'hygiène, des travaux d'aménagement intérieur, de la décoration, et de l'équipement et ameublement des espaces résidentiels ou professionnels.
          </p>
          <p>
            Forts de notre expérience et de notre savoir-faire, nous sommes en mesure d'étudier, de planifier et d'estimer le budget nécessaire pour ces opérations, et nous assurons la gestion complète ou l'accompagnement de chaque projet jusqu'à son aboutissement.
          </p> */}
        //   <p className='mb-5'>
        //   Nous sommes à votre entière disposition pour mener à bien toute mission que vous choisirez de nous confier. Ci-joint, veuillez trouver la plaquette de notre société ainsi que nos derniers tarifs.
        // </p>
        // <p>
        //   Dans l'attente du plaisir de retenir votre attention lors de votre consultation, nous vous prions d'agréer, Madame, Monsieur, l'expression de nos sentiments les plus dévoués.
        // </p>
function ThirdSection() {
  return (
    <div className="thirdSection_about p-5 espaceSection d-flex position-relative gap-5 justify-content-center">
      <div className='d-flex flex-column justify-content-between col-3 gap-3 listP '>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Et, optio soluta accusamus earum atque dolorem dolorum vel, necessitatibus vero impedit autem, modi voluptatem. Harum ipsum assumenda beatae, fugiat aperiam error!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet molestias quisquam ratione rem, nisi incidunt aspernatur. Esse est quae officia cumque. Delectus culpa nulla quae libero id corrupti nihil architecto?</p>
      </div>
      <div  className='background   col-4 '  >
      </div>
      <div className='col-3 d-flex flex-column justify-content-between col-3 gap-5 listP'>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Et, optio soluta accusamus earum atque dolorem dolorum vel, necessitatibus vero impedit autem, modi voluptatem. Harum ipsum assumenda beatae, fugiat aperiam error!</p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet molestias quisquam ratione rem, nisi incidunt aspernatur. Esse est quae officia cumque. Delectus culpa nulla quae libero id corrupti nihil architecto?</p>
      </div>
      <img src={icon1} className='icon' alt="" />
    </div>
  );
}

export default ThirdSection;
